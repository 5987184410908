import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
    selector: 'customradiobox',
    templateUrl: './radio.component.html',
  //  styleUrls: ['./sidebar.component.scss']
})

export class RadioBoxComponent
{
    @Input() RadioBoxText : string = "";
    @Input() RadioBoxName : string = "";
    @Input() RadioBoxClass : string = "";
    @Output() RadioBoxChange : EventEmitter<any> = new EventEmitter();
    constructor
    (
        private elem : ElementRef
    ){}



    Check() {
        let radioElements = document.getElementsByName(this.RadioBoxName);
        for (let i = 0; i < radioElements.length; i++) {
            radioElements[i].parentElement.className = "";
        }
        this.elem.nativeElement.firstChild.getElementsByTagName('span')[0].className = "checked";
        this.RadioBoxChange.emit(this);
    }
}
