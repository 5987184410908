
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { User } from '../user-management/model/user.model';
import { UserPermission } from '../user-management/model/user-permission.model';
import { UserRoleTypeEnum } from '../user-management/enum/user-role-type.enum';
import { UserPermissionEnum } from '../user-management/enum/user-permision.enum';
import { AppConfig } from '../../app.config';
import { Router } from '@angular/router';
import { EnumDomainType } from '../shared/const/enum';

@Injectable()
export class AuthenticationService {
    private _isSuperAdmin: boolean = false;
    get IsSuperAdmin(): boolean { return this._isSuperAdmin; };

    private _isLicenseExpired: boolean = false;
    get IsLicenseExpired(): boolean { return this._isLicenseExpired; };

    private _fullName: string = "";
    get FullName(): string { return this._fullName; };

    private _domainType: EnumDomainType = EnumDomainType.Plant;
    get DomainType(): EnumDomainType { return this._domainType; };

    private _tempNotification: string = ""; // It is temporary msg, once get, it will be cleared
    get TempNotification() {
        setTimeout(() => { this._tempNotification = "" }, 1500)
        return this._tempNotification;
    }

    set TempNotification(value: string) {
        console.log('set', value);
        this._tempNotification = value;
    }

    constructor(private storageService: StorageService, private router: Router) {
        const user = this.getUser();
        if (user) {
            this._isSuperAdmin = user?.roleType == UserRoleTypeEnum.MASTER_ADMIN;
            this._fullName = user?.firstName + ' ' + user?.lastName;
        }
        const domainType = this.storageService.getDomainType();
        if (domainType) this._domainType = domainType;

        this._isLicenseExpired = this.storageService.getIsLicenseExpired();
    }

    //#region  User Login & Logout
    login(
        user: User,
        token: string,
        permissions: UserPermission[],
        screenTimeOut: number,
        domainType: EnumDomainType,
        isLicenseExpired: boolean): void {
        if (user?.userImage)
            user.userImage = AppConfig.settings.endpoint + user.userImage;
            
        this.storageService.setUser(user);
        this.storageService.setToken(token);
        this.storageService.setUserPermissions(permissions);
        this.storageService.setScreenTimeOut(screenTimeOut);
        this.storageService.setDomainType(domainType);
        this.storageService.setIsLicenseExpired(isLicenseExpired);
        this._isSuperAdmin = user?.roleType == UserRoleTypeEnum.MASTER_ADMIN;
        this._fullName = user?.firstName + ' ' + user?.lastName;
        this._domainType = domainType;
        this._isLicenseExpired = isLicenseExpired;
    }

    logout(bAutoLogout: boolean = false): void {
        this.storageService.removeToken();
        this.storageService.removeUser();
        this.storageService.removeUserPermissions();
        this.storageService.removeScreenTimeOut();
        this.storageService.removeDomainType();
        this.storageService.removeIsLicenseExpired();
        this._isSuperAdmin = false;
        this.router.navigate(['/login']);
    }
    //#endregion

    //#region  Storage Access
    getAuthToken(): string | null {
        return this.storageService.getToken();
    }

    getUser(): User | null {
        return this.storageService.getUser();
    }

    getUserPermissions(): UserPermission[] | null {
        return this.storageService.getUserPermissions();
    }

    getScreenTimeOut(): number | null {
        return this.storageService.getScreenTimeOut();
    }

    isAuthenticated(): boolean {
        // If token available then it is authenticated
        // In future can be checked token is expired or not
        return this.storageService.getToken() ? true : false;
    }

    hasPermission(permissionType: UserPermissionEnum): boolean {

        const permissionFun = (permissionType: UserPermissionEnum) => this.storageService.getUserPermissions()
            ?.some(permission => permission.permissionId == permissionType);
        return this._isSuperAdmin ||
            (this.IsLicenseExpired 
                ? (permissionFun(permissionType) && allowedActionsOnLicencedExpired.includes(permissionType))
                : permissionFun(permissionType));
    }

    //Provide support for exsisting code
    get userDetails(): User | null {
        return this.storageService.getUser();
    }
    //#endregion
}



const allowedActionsOnLicencedExpired: UserPermissionEnum[] = [
    UserPermissionEnum.OPEN_FOR_ALL,
    UserPermissionEnum.COMPANY_READ,
    UserPermissionEnum.PLANT_READ,
    UserPermissionEnum.USER_READ,
    UserPermissionEnum.USER_ROLE_READ,
    UserPermissionEnum.USER_ACCOUNT_SETTINGS_READ,
    UserPermissionEnum.REPORT_AUDIT,
    UserPermissionEnum.REPORT_PRODUCTION,
    UserPermissionEnum.PRODUCT_READ,
    UserPermissionEnum.BATCH_READ,
    UserPermissionEnum.PLANT_LINE_READ,
    UserPermissionEnum.EQUIPMENT_READ,
    UserPermissionEnum.LLM_READ,
    UserPermissionEnum.BATCH_SETTINGS_READ,
    UserPermissionEnum.PACKING_SETTINGS_READ,
    UserPermissionEnum.TP_INTEGRATION_SETTINGS_READ,
    UserPermissionEnum.MAH_SETTINGS_READ,
    UserPermissionEnum.LICENCE_SETTINGS_READ,
    UserPermissionEnum.BACKUP_SETTING_READ,
    UserPermissionEnum.BATCH_UNIQE_IDENTITY_READ,
    UserPermissionEnum.DIGITAL_LINK_SETTINGS_READ,
];
