import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as $ from 'jquery';
import 'datatables.net'


@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html'
})

export class DatatableComponent implements OnInit {
  public tableWidget: any;
  public selectedName: string = "";

  @Input() TableName: string = "";
  @Input() TableId: string = "";
  @Input() TableRowClass: string = "";
  @Input() TableArray: any[] = [];
  @Input() TableHeaderArray: any[] = [];
  @Output() TableEvent: EventEmitter<any> = new EventEmitter();

  startIndex: number = 0;
  endIndex: number = 100;
  lastScrollTop: number = 0;
  searchText: string = "";




  constructor() {

    // this.reInitDatatable();
    console.log('DataTable Constructor Get Called');
  }

  ngAfterViewInit() {
    // this.initDatatable();
  }

  private initDatatable(): void {
    let tableId: any = $('#' + this.TableId);
    this.tableWidget = tableId.DataTable({
      select: true
    });
    this.tableWidget.on('page.dt', function (event) {
      let info = this.tableWidget.page.info();
      let page = info.page + 1;
      this.startIndex = (info.page - 1) * 10;
      this.endIndex = Math.min(this.startindex + 10, this.TableArray.length - 1);
    });
    //   $('#example')
    //     .removeClass('display')
    //     .addClass('table table-striped table-bordered')
  }

  private reInitDatatable(): void {
    this.startIndex = 0;
    this.endIndex = 100;
    this.lastScrollTop = 0;
    // if (this.tableWidget) {
    //   this.tableWidget.destroy()
    //   this.tableWidget = null
    // }
    // setTimeout(() => this.initDatatable(), 500)
  }

  public deleteRow(): void {
    this.reInitDatatable()
  }

  public addRow(): void {
    this.reInitDatatable()
  }

  public selectRow(index: number, row: any) {
    this.selectedName = "row#" + index + " " + row.name;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.TableArray.firstChange === false) {
      this.reInitDatatable();
    }
    //this.doSomething(changes.TableArray.currentValue);
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values

  }

  onScrollDown($event) {
    const scrolled = $event.target.scrollTop;
    const height = $event.target.offsetHeight;
    if (scrolled > height) {
      if (this.endIndex <= this.TableArray.length) {
        console.log($event);
        // console.log(Math.abs(height - scrolled));
        // let currentHeight = Math.abs(height - scrolled);
       
        this.lastScrollTop = ($event.target.scrollTop);
        if (scrolled > 4000) {
          this.startIndex = this.endIndex;
          this.endIndex = this.endIndex + 100;
          $event.target.scrollTop = 4000;
        }
      }
      //$event.target = currentHeight;
    }
    else if( this.startIndex > 50 ) {
      if (this.endIndex > 100) {
        this.startIndex = Math.abs(this.startIndex - 100);
        this.endIndex = Math.abs(this.endIndex - 100);
        if(this.lastScrollTop < 500)
        {
          $event.target.scrollTop = 350;
        }
      }
    }
  }

  ngOnInit() {
    this.reInitDatatable();
  }

}
