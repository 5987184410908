import { Component , ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';

import {LoaderService} from './services/loader.service';
import { AutoLogoutService } from './services/auto-logout.service';
import { ConfirmDialogBox } from './models/common/ConfirmDialog.model';

declare var $: any;
declare function modal() : any;
//declare function tooltip() : any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Kevision System';
  showLoader: boolean;
  ConfirmDialogMsg:string = "";

  constructor(
    private router: Router,
    private loaderService:LoaderService,
    public cdr : ChangeDetectorRef
    ) { }

  ngAfterViewInit() {
    this.loaderService.status.subscribe((val: boolean) => {
       // this.showLoader = val;
        if (val) {
          $('#pleaseWaitModal').modal('show');
        } else {
          setTimeout(() => { $('#pleaseWaitModal').modal('hide'); }, 500);
        }
        this.cdr.detectChanges();
    });

    this.loaderService.confirmDialog.subscribe((val:ConfirmDialogBox)=>{
      this.ConfirmDialogMsg = "";
      if(val == null)
      {
        return;
      }      
      this.ConfirmDialogMsg = val.confirmDialogMsg;
      (<any>$("#modal-confirm")).modal("show");
      $("#modal-confirm").on("hide.bs.modal",this.onConfirmModalHide); 
      $("#modal-confirmOk").on("click",val,this.OnConfirmOkClicked);
    });
  }  
  
  onConfirmModalHide(evt) {
      $("#modal-confirmOk").off();
  }

  OnConfirmOkClicked(evt){
      (<any>$("#modal-confirm")).modal("hide"); 
      if(evt == null || evt.data == null)
      {
          return;
      }       
      var val : ConfirmDialogBox = evt.data;
      val.callbackFunction(val.callbackData);
  }

  chkAuthenticate() : void {
    this.router.navigateByUrl('/dashboard');
  }

}
