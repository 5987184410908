import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfirmDialogBox } from '../models/common/ConfirmDialog.model';
import { AuthenticationDialog } from '../models/common/AuthenticationDialog.model';
import { UserPermissionEnum } from '../user-management/enum/user-permision.enum';

@Injectable()
export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public confirmDialog: BehaviorSubject<ConfirmDialogBox> = new BehaviorSubject<ConfirmDialogBox>(null);
    public hideModalOnLogout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public authDialogBox: Subject<AuthenticationDialog> = new Subject<AuthenticationDialog>();

    display(value: boolean) {
        this.status.next(value);
       if(document.body.style.paddingRight != "")
       {
           document.body.style.paddingRight = null;
       }
    }

    confirmDialogShow(data:ConfirmDialogBox)
    {
        this.confirmDialog.next(data);
    }

    //functionName : will call if authentication verified, and pass user name in function params.
    //permission : Permission for action. 'Write','Update' and 'Read' these are levels.   
    Authenticate(callbackFunction:any, permission: UserPermissionEnum = UserPermissionEnum.OPEN_FOR_ALL, selfAuthentication: boolean = true){
        this.authDialogBox.next(new AuthenticationDialog(callbackFunction, permission ,selfAuthentication));
    }
}