import { Injectable ,Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from './app-config.model';

@Injectable()

export class AppConfig {
  public static settings: IAppConfig;
    http:HttpClient;
    constructor(inj: Injector) {
        this.http=inj.get(HttpClient)
    }
    load() {
        const jsonFile = `./config/config.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response : IAppConfig) => {
               AppConfig.settings = <IAppConfig>response;
               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}


