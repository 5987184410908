import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../services/auth.service";

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const accessType = route.data['accessType'];

    if (accessType && this.authService.hasPermission(accessType))
      return true;
    
    void this.router.navigate(['/no-access']);
    return false;
  }
}
