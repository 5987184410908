<!-- <div class="tile-stats {{bgClass}}">
    <div class="icon"><i class="fa {{icon}}"></i></div>
    <div class="count">{{count}}</div>
    <h3>{{label}}</h3>
 <div class="panel {{bgClass}}">
    <div class="panel-body">
        <div class="heading-elements">
            <div class="icons-list">
                <i class="fa {{icon}}"></i>
            </div>
            <div class="counter-icon">{{count}}</div>
        </div>
        <h3 class="no-margin">{{label}}</h3>
    </div>
</div> -->

<!-- <div class="card text-white {{bgClass}} py-2">
    
        <div class="card-body">
          <a href="#" [routerLink]="[actionLinks[0].url]" class="text-white">
              <div class="box">
                    <div class="rotate">
                            <i class="fa {{logoClass}} fa-5x"></i>
                        </div>
              </div>
            <h5 class="text-uppercase"> {{label}} 
            </h5>
            <h1 class="display-4">{{count}}</h1>
        </a>
            <div class="heading-elements not-collapsible" style="top:35px">
                <ul class="icons-list">
                    <li class="dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="icon-more"></i> </a>
                        <ul class="dropdown-menu dropdown-menu-right" >
                            <li *ngFor="let item of actionLinks"  ><a [routerLink]="item.url" *ngIf="item.isViewable" ><i class="{{item.icon}}" ></i> {{item.actionName}}</a></li>
                        </ul>
                    </li>
                </ul>
             </div>
        </div>
 </div> -->




 <div class="card card-body cursor-pointer" style="border-radius:5px;border: none;"  title="{{actionLinks && actionLinks[0] ? actionLinks[0].actionName : ''}}" 
  [routerLink]="[actionLinks && actionLinks[0] ? actionLinks[0].url : '']">

    <div class="media" style="display: flex;">

        <div class="media-left" href="#">
            <div style="width:44px;height:44px;position:absolute;z-index: 2;">
                <i class="fa {{logoClass}}" style="width:44px;line-height:44px;text-align:center;vertical-align:middle;font-size:20px; "></i>
            </div>

            <span class="img-lg img-circle text-white text-center {{bgClass}}" style="opacity: 0.2; display: block; line-height: 44px; vertical-align: middle;">

            </span>
        </div>

        <div class="media-body" style="width: 75%;">
            <div class="cursor-pointer" style="width:100%;" title="{{actionLinks && actionLinks[0] ? actionLinks[0].actionName : ''}}" href="#" [routerLink]="[actionLinks && actionLinks[0] ? actionLinks[0].url : '']">

                <h1 class="media-heading word-wrap text-capitalize font-weight-bold">
                    {{count}}
                </h1>
                <h5 class="media-heading word-wrap text-capitalize text-muted">
                    {{label}}
                </h5>
            </div>
        </div>
    </div>

    <!-- <div class="heading-elements not-collapsible" style="top:5px;right:10px;cursor: pointer;">
        <ul class="icons-list">
            <li class="dropdown">
                <div href="#"  data-toggle="dropdown" aria-expanded="false"><i class="icon-more2"></i> </div>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li class="dropdown-item" *ngFor="let item of actionLinks" [routerLink]="item.url" ><i class="{{item.icon}}" ></i> {{item.actionName}}</li>
                </ul>
            </li>
        </ul>
    </div> -->

</div>






    <!-- <a href="#" [routerLink]="[actionLinks[0].url]" class="text-white">
        <div class="panel">

                <div class="panel-heading  {{bgClass}}">
                    <h5 class="panel-title" style=" text-align: center;">
                                        <span class="count-large">{{count}}</span>
                    </h5>
                </div>
                <div class="panel-body {{bgText}}">
                        <h2 class="panel-title ">
                                    <div>
                                            {{label}}
                                            <a class="heading-elements-toggle" mobileElement><i class="icon-more"></i></a>
                                    </div>
                        </h2>
                        <div class="heading-elements">
                            <ul class="icons-list">
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="icon-menu9"></i> </a>
                                    <ul class="dropdown-menu dropdown-menu-right" >
                                        <li *ngFor="let item of actionLinks"  ><a [routerLink]="item.url" *ngIf="item.isViewable" ><i class="{{item.icon}}" ></i> {{item.actionName}}</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                </div>
        </div>
    </a> -->
