import { Directive, ElementRef } from "@angular/core";
@Directive({ selector: '[notify]' })

export class NotificationDirective {
    constructor(private el: ElementRef) {
        // console.log('Notification Initialized');
        // el.nativeElement.innerHTML = '<div class="alert alert-danger alert-dismissable fade in">Error</div>';
    }

    RenderMessage(message: string, bgclass: string, statustype: string) {
        const uniqueId = this.getUniqueId();
        this.el.nativeElement.innerHTML += `<div id="` + uniqueId + `" class="animated fadeInLeft alert ` + bgclass + ` alert-dismissable">
                                            <a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a>
                                            <strong>`+ statustype + `</strong>
                                            `+ message + `
                                            </div>`;
        setTimeout(() => { this.el.nativeElement.innerHTML = ''; }, 10000);
        this.scrollToTop();
    }

    SuccessMessage(message: string) {
        const uniqueId = this.getUniqueId();
        this.el.nativeElement.innerHTML += `<div id="` + uniqueId + `" class="animated rubberBand alert alert-success alert-bordered alert-dismissable">
        <a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a>
        <span class="text-semibold"><b><i class='icon-checkmark2'></i> Success: </b></span>
        `+ message + `
        </div>`;

        setTimeout(() => { this.removeElement(uniqueId) }, 10000);
        this.scrollToTop();
    }

    DangerMessage(message: string) {
        const uniqueId = this.getUniqueId();
        this.el.nativeElement.innerHTML += `<div id="` + uniqueId + `" class="animated shake alert alert-danger alert-bordered alert-dismissable">
        <a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a>
        <span class="text-semibold"><b><i class='icon-notification2'></i></b></span>
        `+ message + `
       </div>`;
        setTimeout(() => { this.removeElement(uniqueId) }, 10000);
        this.scrollToTop();
    }

    DangerMessageList(message: string[]) {
        const uniqueId = this.getUniqueId();
        let errorlist: string = "";
        message.forEach(element => {
            errorlist += ' <li>' + element + '</li>';
        });
        this.el.nativeElement.innerHTML += `<div id="` + uniqueId + `" class="animated shake alert alert-danger alert-bordered alert-dismissable"><a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a><ul>'+errorlist+'</ul></div>`;
        setTimeout(() => { this.removeElement(uniqueId) }, 10000);
        this.scrollToTop();
    }
    UploadRenderMessage(message: string, bgclass: string, statustype: string) {
        const uniqueId = this.getUniqueId();
        this.el.nativeElement.innerHTML += `<div id="` + uniqueId + `" class="animated fadeInLeft alert ` + bgclass + ` alert-dismissable">
                                            <a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a>
                                            <strong>`+ statustype + `</strong>
                                            `+ message + `
                                            </div>`;
        // setTimeout(() => { this.el.nativeElement.innerHTML = '';},10000);
        this.scrollToTop();
    }

    ResetMessage() {
        this.el.nativeElement.innerHTML = "";
    }

    scrollToTop() {
        this.el.nativeElement.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });
    }


    private getUniqueId() {
        return 'id' + Math.random().toString(36).substring(8);
    }

    private removeElement(id: string) {
        $(this.el.nativeElement).find(`#` + id).remove();
    }
}
