        <div class="error-wrapper">
          <div class="error-inner">
            <img
              class="light-image"
              src="../../assets/gentella/images/404.svg"
              alt=""
              width="70%"
            />
            <h3 class="dark-inverted">We couldn't find that page</h3>
            <p>Page you were trying to reach on a website couldn't be found.</p>
            <div class="button-wrap">
              <div>
                <button type="button" class="btn btn-danger btn-lg" (click)="navigateToPrevUrl()">
                    Take me back
                </button>
            </div>
            </div>
          </div>
        </div>
