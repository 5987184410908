import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../services/auth.service';
import { BatchSystemSettingsEnum } from '../../const/enum';
import { NotificationDirective } from '../../../directive/notification.directive';
import { NavbarItem } from './navbar-item.model';
import { sidebarItems } from './navbar.const';
import { UserPermissionEnum } from '../../../user-management/enum/user-permision.enum';
import { User } from '../../../user-management/model/user.model';
import { AppConfig } from '../../../../../src/app.config';

@Component({
    selector: 'div.sidebar',
    templateUrl: './sidebar.component.html',
    //  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    isActive = false;
    showMenu = '';
    isEnablePrintQueueAccess = false;
    isBatchUniqueIdentity = false;
    batchSystemSettingsEnum = BatchSystemSettingsEnum;
    appversion: string = AppConfig.settings.version;
    @ViewChild(NotificationDirective) notify: NotificationDirective;
    user: User;
    navbarItems: NavbarItem[] = [];
    constructor(
        public authService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.setNavbarItem();
        this.user = this.authService.getUser();
    }

    setNavbarItem(): void {
        if (this.authService.IsSuperAdmin) {
            this.navbarItems = sidebarItems;
            return;
        }
        const permissions = this.authService.getUserPermissions();
        const hasPermission = (permissionType: UserPermissionEnum): boolean => permissions?.some(permission => permission.permissionId == permissionType);

        const getNavbarList = (navItems: NavbarItem[]) => {
            const navbarLst: NavbarItem[] = [];
            navItems.forEach((nav) => {
                if (nav.children.length > 0) {
                    const lst = getNavbarList(nav.children);
                    if (lst.length > 0) {
                        nav.children = lst;
                        navbarLst.push(nav);
                    }
                } else if (nav.permission == UserPermissionEnum.OPEN_FOR_ALL || hasPermission(nav.permission)) navbarLst.push(nav);
            });
            return navbarLst;
        };
        this.navbarItems = getNavbarList(JSON.parse(JSON.stringify(sidebarItems)));
    }
    
    openAppVersionModal() {
        (<any>$("#appVersionModal")).modal('show');        
      }

}
