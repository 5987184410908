/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { UserPermission } from '../user-management/model/user-permission.model';
import { User } from '../user-management/model/user.model';
import { EnumDomainType } from '../shared/const/enum';

@Injectable()
export class StorageService {
  private readonly TOKEN_KEY = 'token';
  private readonly USER_KEY = 'user';
  private readonly USER_PERMISSIONS_KEY = 'permissions';
  private readonly SCREEN_TIME_OUT_KEY = 'screen-timeout';
  private readonly DOMAIN_TYPE_KEY = 'domain-type';
  private readonly IS_LICENSE_EXPIRED = 'is-liense-expired';

  private _permissions: UserPermission[] | null = null;
  private _token: string | null = null;
  private _user: User | null = null;

  // #region Token Storage Start
  getToken(): string | null {
    if(this._token) return this._token;
    return sessionStorage.getItem(this.TOKEN_KEY);
  }

  setToken(token: string): void {
    this._token = token;
    sessionStorage.setItem(this.TOKEN_KEY, token);
  }

  removeToken() {
    this._token = null;
    sessionStorage.removeItem(this.TOKEN_KEY);
  }
  // #endregion

  // #region User Storage Start
  getUser(): User | null {
    if(this._user) return this._user;
    const str = sessionStorage.getItem(this.USER_KEY);
    if (str) {
      return JSON.parse(str);
    }
    return null;
  }

  setUser(user: User) {
    if (user) {      
      this._user = user;
      sessionStorage.setItem(this.USER_KEY, JSON.stringify(user));
    } 
  }

  removeUser() {
    this._user = null;
    sessionStorage.removeItem(this.USER_KEY);
  }
  // #endregion

  // #region User Access Modules Storage Start
  getUserPermissions(): UserPermission[] | null {
    if (this._permissions) return this._permissions;
    const str = sessionStorage.getItem(this.USER_PERMISSIONS_KEY);
    if (str) {
      const permissions: UserPermission[] = JSON.parse(str);
      this._permissions = permissions;
      return permissions;
    }
    return null;
  }

  setUserPermissions(permissions: UserPermission[]) {
    this._permissions = permissions;
    sessionStorage.setItem(this.USER_PERMISSIONS_KEY, JSON.stringify(permissions));
  }

  removeUserPermissions() {
    this._permissions = null;
    sessionStorage.removeItem(this.USER_PERMISSIONS_KEY);
  }
  // #endregion

  //#region  screenTimeOut
  getScreenTimeOut(): number | null {
    const str = sessionStorage.getItem(this.SCREEN_TIME_OUT_KEY);
    if (str) {
      return JSON.parse(str);
    }
    return null;
  }

  setScreenTimeOut(screenTimeOut: number) {
    sessionStorage.setItem(this.SCREEN_TIME_OUT_KEY, JSON.stringify(screenTimeOut));
  }

  removeScreenTimeOut() {
    sessionStorage.removeItem(this.SCREEN_TIME_OUT_KEY);
  }
  //#endregion

  //#region  screenTimeOut
  getDomainType(): EnumDomainType | null {
    const str = sessionStorage.getItem(this.DOMAIN_TYPE_KEY);
    if (str) {
      return JSON.parse(str);
    }
    return null;
  }

  setDomainType(domainType: EnumDomainType) {
    sessionStorage.setItem(this.DOMAIN_TYPE_KEY, JSON.stringify(domainType));
  }

  removeDomainType() {
    sessionStorage.removeItem(this.DOMAIN_TYPE_KEY);
  }
  //#endregion


  //#region  screenTimeOut
  getIsLicenseExpired(): boolean | null {
    const str = sessionStorage.getItem(this.IS_LICENSE_EXPIRED);
    if (str) {
      return JSON.parse(str);
    }
    return null;
  }

  setIsLicenseExpired(isLicenseExpired: boolean) {
    sessionStorage.setItem(this.IS_LICENSE_EXPIRED, JSON.stringify(isLicenseExpired));
  }

  removeIsLicenseExpired() {
    sessionStorage.removeItem(this.IS_LICENSE_EXPIRED);
  }
  //#endregion
}
