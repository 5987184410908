import { UserPermissionEnum } from "../../../user-management/enum/user-permision.enum";

export class NavbarItem {
    routerLink: string[] = [];
    label = '';
    iconClass = '';
    children: NavbarItem[] = [];
    permission!: UserPermissionEnum;
    public constructor(init?: Partial<NavbarItem>) {
      Object.assign(this, init);
    }
  }
  