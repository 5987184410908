<div class="sidebar-content" style="display: flex; flex-direction: column;">
  <div style="flex: 1;">
    <!-- Main Navigation -->
    <!-- <div class="sidebar-user">
      <div class="card-body">
        <div class="media" *ngIf="user">
          <div class="mr-3">
            <a [routerLink]="['/user/profile',user._id]">
              <div class="avatar-container-sm" [innerHTML]="user | profileOrName">
              </div>
            </a>
          </div>
  
          <div class="media-body">
            <div class="media-title font-weight-semibold">{{user.username}}</div>
            <div class="font-size-xs opacity-50">
              <i class="icon-envelop font-size-sm"></i> {{user.email}}
            </div>
          </div>
        </div>
      </div>
    </div> -->


    <div class="card card-sidebar-mobile">
      <ul class="nav nav-sidebar" data-nav-type="accordion">
        <ng-container *ngFor="let item of navbarItems">
          <ng-container *ngTemplateOutlet="item.children.length>0 ? navbarItemParent : navbarItem; context:{item:item}">
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
  
  <div class="text-muted cursor-pointer" style="text-align: center;">
    <span (click)="openAppVersionModal()">
      Version <b>{{appversion}}</b>
    </span>
  </div>
</div>

<ng-template #navbarItem let-item='item' let-i="index">
  <li class="nav-item">
    <a [routerLink]="item.routerLink" [routerLinkActive]="['active']" class="nav-link"><i
        [ngClass]="item.iconClass"></i><span>{{item.label}}</span></a>
  </li>
</ng-template>

<ng-template #navbarItemParent let-item='item'>
  <li class="nav-item nav-item-submenu">
    <a href="#" class="nav-link has-ul"><i><span [ngClass]="item.iconClass"></span></i><span>{{item.label}}</span></a>
    <ul id="settings-child-list" class="nav nav-group-sub" [attr.data-submenu-title]="item.label">
      <ng-container *ngFor="let child of item.children" [ngTemplateOutlet]="navbarItem"
        [ngTemplateOutletContext]="{item:child}">
      </ng-container>
    </ul>
  </li>
</ng-template>