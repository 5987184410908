<body class="bg-white">
    <div class="error-wrapper">
        <div class="error-inner">
            <img class="light-image" src="../../assets/gentella/images/401.svg" alt="" width="70%" />
            <h3 class="dark-inverted">Sorry, you cannot view the page.</h3>
            <p>No permission to access this page. Please contact administrator</p>
            <div>
                <button type="button" class="btn btn-danger btn-lg" (click)="navigateToPrevUrl()">
                    Take me back
                </button>
            </div>
        </div>
    </div>
</body>