export enum BatchSystemSettingsEnum {
    BulkPrint = 'Bulk Print',
    MFGDate = 'Manufacturing Date As Per GS1',
    PrintQueue = 'Required System Generated PDF',
    Commission = 'Is Product Verification Required Before Commission',
    ApproveorReject = "Batch Approval And Rejection Are Required",
    BatchUniqueIdentity = 'Is Batch Unique Identity Number Required'
}
export enum MFGDateYearEnum {
    GS149 = '49'
}
export enum PrintStatusEnum {
    Pending,
    Queue,
    Done
}
export enum EnumPlantType {
    Plant,
    CMO
}
export enum EnumDomainType {
    MainDomain = 1,
    SubDomain = 2,
    Plant = 3
}
export enum EnumBatchQuantityType {
    Total,
    Sample
}
export enum BooleanEnum {
    Yes = 'true',
    No = 'false'
}
export enum DateFormatEnum {
    MonthYear = 'YYMM00',
    DateMonthYear = 'YYMMDD'
}
export enum UniqueIdentityEnum {
    BatchNo = 'Batch No',
    BatchGTIN = 'Batch No + GTIN',
    PONumber = 'PO Number'
}
export enum GTINFormatEnum {
    "GTIN-8" = 8,
    "GTIN-12" = 12,
    "GTIN-13" = 13,
    "GTIN-14" = 14
}

export enum BatchTypeEnum {
    BatchSerialzation ="BATCH-SERIALIZATION",
    uid ="UID",
    uidsscc = "UID/SSCC",
    sscc = "SSCC" 
}
export enum StatusEnum {
    ACTIVE = 1,
    INACTIVE = 2
}
export enum EnumReconcilationMode {
    Basic="Basic",
    Sync="Sync"
}
export enum EnumLevelSerializationType {
    BATCH_SERIALIZATION,
    SERIALIZATION,
    PCR
}
export enum EnumSystemSettingSerializationType {
    BATCH_SERIALIZATION,
    PRODUCT_SERIALIZATION,
    BATCHAndPRODUCT_SERIALIZATION
}

export enum EnumSyncRequestType {
    Send,
    Received,
    Upload,
    Download,
    Request
}

export enum BackGroundJobStatusEnum{
    Processing = 0,
    Succeeded = 1,
    Failed = 2
}

export enum EnumDigitalLinkType {
    Regular = 0,
    Compressed = 1,
    Partial_Compressed = 2
}