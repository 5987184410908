import {Component ,OnInit,Input,Output,EventEmitter} from '@angular/core';
//Import Routing Module
import { Router } from '@angular/router';


@Component({
    selector :'app-stat',
    templateUrl : './stat.component.html'
})

export class StatComponent implements OnInit {
    @Input() bgClass :string;
    @Input() logoClass :string;
    
   // @Input() icon : string;
    @Input() count : number;
    @Input() label : string;
    @Input() sublabel : string;
   // @Input() link : string;
    @Input() data : number;
    @Input() actionLinks : any[];
    @Input() isViewable : boolean = false;
    @Output() event:EventEmitter<any> = new EventEmitter();

    constructor(){}

    ngOnInit() {}

}
