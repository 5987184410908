import { Component, Input,Output,EventEmitter,forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
    
};

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckBoxComponent),
    multi: true
};

@Component({
    selector: 'customcheckbox',
    templateUrl: './checkbox.component.html',
  //  styleUrls: ['./sidebar.component.scss'],
    providers: [INPUT_CONTROL_VALUE_ACCESSOR]
})

export class CheckBoxComponent implements ControlValueAccessor
{
   // @Input() CheckBoxValue : boolean = false;
    @Input() DisableCheckBox:boolean = false;
    @Input() CheckBoxId : string = "";
    @Input() CheckBoxName : string = "";
    @Input() CheckBoxText: string = "";

    @Output() CheckBoxChange : EventEmitter<any> = new EventEmitter();
    constructor(){}


      //The internal data model
      private innerValue: any = '';

      //Placeholders for the callbacks which are later providesd
      //by the Control Value Accessor
      private onTouchedCallback: () => void = noop;
      private onChangeCallback: (_: any) => void = noop;
  
      //get accessor
      get value(): any {
          return this.innerValue;
      };
  
      //set accessor including call the onchange callback
      set value(v: any) {
          if (v !== this.innerValue && !this.DisableCheckBox) {
              this.innerValue = v;
              this.onChangeCallback(v);
              this.CheckBoxChange.emit(this);
          }
      }
        
      @Input() set CheckBoxValue(v: any) {
        
         if (v !== this.value) {
             this.innerValue = v;
             this.CheckBoxChange.emit(this);
       }
    }
      get CheckBoxValue(): any {
        return this.value;
      }
      
      //From ControlValueAccessor interface
      writeValue(value: any) {
          if (value !== this.innerValue) {
              this.innerValue = value;
          }
      }
  
      //From ControlValueAccessor interface
      registerOnChange(fn: any) {
          this.onChangeCallback = fn;
      }
  
      //From ControlValueAccessor interface
      registerOnTouched(fn: any) {
          this.onTouchedCallback = fn;
      }
}

