import { NgModule ,APP_INITIALIZER  } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppConfig } from './app.config';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  imports: [
    HttpClientModule
  ],
  declarations: [
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfig],
      useFactory: initializeApp
    }]
   
})
export class AppConfigModule { }
