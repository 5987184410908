export enum UserPermissionEnum {

    OPEN_FOR_ALL = -100000,
    NONE = -1,

    //#region Company 1-50
    COMPANY_READ = 1, //Company Read - Allows to read Company Master.
    COMPANY_CREATE = 2, //Company Create - Allows to create a new company in Company Master.
    COMPANY_UPDATE = 3, //Company Update - Allows to update details in Company Master.
    COMPANY_STATUS_CHANGE = 4, //Company Change Status - Allows to activate or deactivate the company.
    //#endregion

    //#region Plant 51-100
    PLANT_READ = 51, //Plant Read - Allows to read plant details.
    PLANT_CREATE = 52, //Plant Create - Allows to create new plant and add its details.
    PLANT_UPDATE = 53, //Plant Update - Allows to update details in existing plant.
    PLANT_STATUS_CHANGE = 54, //Plant Change Status - Allows to activate or deactivate the plant.
    //#endregion

    //#region User 101-150
    USER_READ = 101, //User Read - Allows to read User Master.
    USER_CREATE = 102, //User Create - Allows to create other user and assign role.
    USER_UPDATE = 103, //User Update - Allows to update user details and change its role.
    USER_STATUS_CHANGE = 104, //User Change Status - Allows to activate or deactivate the user.
    USER_RESET_PASSWORD = 105, //User Reset Password - Allows to reset password of other users.
    //#endregion

    //#region User Role 151-200,
    USER_ROLE_READ = 151, //User Read Role - Allows to read all user roles.
    USER_ROLE_CREATE = 152, //User Create Role - Allows to create new user role.
    USER_ROLE_UPDATE = 153,  //User Update Role - Allows to update role.
    USER_ROLE_STATUS_CHANGE = 154, //User Change Role Status - Allows to activate or deactivate user role.
    //#endregion

    //#region User Account Settings 201-250
    USER_ACCOUNT_SETTINGS_READ = 201, //User Read Account Settings - Allows to read user account settings.
    USER_ACCOUNT_SETTINGS_CREATE = 202, //User Create Account - Allows to create new user.
    USER_ACCOUNT_SETTINGS_UPDATE = 203, //User Update Settings - Allows to update user account settings.
    //#endregion

    //#region Reports 251-300
    REPORT_AUDIT = 251, //Report View Audit - Allows to view audit report.
    REPORT_PRODUCTION = 252, //Report View Production - Allows to view production report.
    REPORT_USER = 253, //Report View User - Allows to view user report.
    REPORT_BACKGROUND_JOB = 254, //Report View Background Job - Allows to view background job report.
    REPORT_BATCH_AUDIT = 255, //Report View Batch Audit - Allows to view batch audit report.
    //#endregion

    //#region Product Master 301-400
    PRODUCT_READ = 301, //Product Read - Allows to read product details.
    PRODUCT_CREATE = 302, //Product Create - Allows to create new product.
    PRODUCT_UPDATE = 303,  //Product Update - Allows to update product details.
    PRODUCT_STATUS_CHANGE = 304, //Product Change Status - Allows to activate or deactivate product.
    //#endregion

    //#region Batch 401-500
    BATCH_READ = 401, //Batch Read - Allows to read batch master.
    BATCH_CREATE = 402, //Batch Create - Allows to create new batch.
    BATCH_UPDATE = 403, //Batch Update - Allows to update batch details.
    BATCH_STATUS_CHANGE = 404, //Batch Change Status - Allows to change batch status.
    BATCH_RECONCILIATION = 405, //Batch Sampling - Allows to do sampling of product in batch.
    BATCH_LINE_TRANSFER = 406, //Batch Line Transfer - Allows to transfer batch from one line to another.
    BATCH_APPROVE = 407, //Batch Approve - Allows to approve batch.
    BATCH_DOWNLOAD = 408, //Batch Download - Allows to download batch.
    BATCH_UPLOAD = 409, //Batch Upload - Allows to upload batch.
    BATCH_RECONCILIATION_Authentication=410, //Batch Sampling Authentication - Allows to do sampling of Review Authentication in batch.
    //#endregion

    //#region Plant Line 501-550
    PLANT_LINE_READ = 501, //Plantline Read Details - Allows to read plant line details.
    PLANT_LINE_CREATE = 502, //Plantline Create - Allows to create new plant line.
    PLANT_LINE_UPDATE = 503, //Plantline Update - Allows to update plant line details.
    PLANT_LINE_STATUS_CHANGE = 504, //Plantline Change Status - Allows to activate or deactivate Plantline.
    //#endregion

    //#region Equipment 551-600
    EQUIPMENT_READ = 551, //Equipment Read Details - Allows to read equipment details.
    EQUIPMENT_CREATE = 552, //Equipment Create - Allows to create new equipment.
    EQUIPMENT_UPDATE = 553, //Equipment Update - Allows to update equipment details.
    EQUIPMENT_STATUS_CHANGE = 554, //Equipment Change Status - Allows to activate or deactivate equipment.
    //#endregion

    //#region LLM 601-650
    LLM_READ = 601, //LLM/CMO Read - Allows to read LLM/CMO details.
    LLM_CREATE = 602, //LLM/CMO Create - Allows to create LLM/CMO.
    LLM_UPDATE = 603, //LLM/CMO Update - Allows to update LLM/CMO details.
    LLM_STATUS_CHANGE = 604, //LLM/CMO Change status - Allows to activate or deactivate LLM/CMO.
    //#endregion

    //#region Batch Settings 651-700 
    BATCH_SETTINGS_READ = 651, //Batch Settings Read - Allows to read batch setting details.
    BATCH_SETTINGS_CREATE = 652, //Batch Settings Create - Allows to create new batch settings.
    BATCH_SETTINGS_UPDATE = 653, //Batch Settings Update - Allows to update batch settings.
    //#endregion 

    //#region Packing Level Settings 701-750
    PACKING_SETTINGS_READ = 701, //Packing Level Read Details - Allows to read packing level details.
    PACKING_SETTINGS_CREATE = 702, //Packing Level Create - Allows to create new packing level.
    PACKING_SETTINGS_UPDATE = 703, //Packing Level Update - Allows to update packing level details.
    //#endregion

    //#region Third Party Integration Settings 751-800
    TP_INTEGRATION_SETTINGS_READ = 751, //Third-party Read - Allows to read third-party details.
    TP_INTEGRATION_SETTINGS_CREATE = 752, //Third-party Create - Allows to create new third-party entry.
    TP_INTEGRATION_SETTINGS_UPDATE = 753, //Third-party Update - Allows to update third-party details.
    TP_INTEGRATION_SETTINGS_DElETE = 754, //Third-party Delete - Allows to Detete third-party details.
    TP_INTEGRATION_STATUS_CHANGE = 755, //Third-party Change Status-  Allows to activate or deactivate third-party Status.
    //#endregion

    //#region MAH Settings 801-850
    MAH_SETTINGS_READ = 801, //MAH Read - Allows to read MAH details.
    MAH_SETTINGS_CREATE = 802, //MAH Create - Allows to create new MAH.
    MAH_SETTINGS_UPDATE = 803, //MAH Update - Allows to update MAH details.
    MAH_SETTINGS_STATUS_CHANGE = 804, //MAH Change Status-  Allows to activate or deactivate MAH Status.
    //#endregion

    //#region System Settings 851-900
    SYSTEM_SETTINGS_READ = 851, //System Settings Read - Allows to read system settings.
    SYSTEM_SETTINGS_CREATE = 852, //System Settings Create - Allows to create system settings.
    SYSTEM_SETTINGS_UPDATE = 853, //System Settings Update - Allows to update system settings.
    //#endregion

    //#region Licence Settings 901-950
    LICENCE_SETTINGS_READ = 901, //License Read - Allows to read current license.
    LICENCE_SETTINGS_CREATE = 902, //License Create - Allows to create current license.
    LICENCE_SETTINGS_UPDATE = 903, //License Update - Allows to update current license.
    //#endregion

    //#region Built-in User Role 951-1000
    BUILT_IN_USER_ROLE_READ = 951, //Built-in User Roles Read - Allows to read build-in user roles.
    BUILT_IN_USER_ROLE_CREATE = 952, //Built-in User Roles Create - Allows to create new built-in user roles.
    BUILT_IN_USER_ROLE_UPDATE = 953, //Built-in User Roles Update - Allows to update built-in user roles.
    //#endregion

    //#region Backup Settings 1001-1050
    BACKUP_SETTING_READ = 1001, //Backup Setting Read - Allows to read backup setting.
    BACKUP_SETTING_CREATE = 1002, //Backup Setting Create - Allows to create new backup setting.
    BACKUP_SETTING_UPDATE = 1003, //Backup Setting Update - Allows to update backup setting.
    BACKUP_SETTING_TAKE_BACKUP = 1004, //Backup Setting Schedule Backup - Allows to schedule database backup.
    BACKUP_SETTING_RESTORE = 1005, //Backup Setting Restore Backup - Allows to restore backup database.
    //#endregion

    //#region Batch Uniqe Identity 1051-1100
    BATCH_UNIQE_IDENTITY_READ = 1051, //Batch Uniqe Identity Read - Allows to view batch unique identity page.
    BATCH_UNIQE_IDENTITY_CREATE = 1052, //Batch Uniqe Identity Create - Allows to create new batch unique identity.
    BATCH_UNIQE_IDENTITY_UPDATE = 1053, //Batch Uniqe Identity Update - Allows to update batch unique identity.
    //#endregion

    //#region Web Link Settings 1101-1150
    DIGITAL_LINK_SETTINGS_READ = 1101, //Digital Link Settings View - Allows to view digital link settings page.
    DIGITAL_LINK_SETTINGS_CREATE = 1102, //Digital Link Settings Create - Allows to create new digital link settings.
    DIGITAL_LINK_SETTINGS_UPDATE = 1103, //Digital Link Settings Update - Allows to update digital link settings.
    //#endregion

    //#region Email Notification Master 1151-1200
    EMAIL_NOTIFICATION_MASTER_READ = 1151, //Email Notification Master View - Allows to view email notification master.
    EMAIL_NOTIFICATION_MASTER_UPDATE = 1152, //Email Notification Master Update - Allows to update email notification master.
    //#endregion

    //#region Reconciliation  1201-1250
        RECONCILIATION_READ = 1201, //Reconciliation View - Allows to view reconciliation.   
        RECONCILIATION_UPDATE = 1202, //Reconciliation Update - Allows to update reconciliation.
    //#endregion
    
    //#region Sync History 1251-1300
        SYNC_HISTORY_READ = 1251 //Sync History View - Allows to view sync history.
    //#endregion
} 
