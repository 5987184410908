import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared';
import { AuthGuard } from './shared';
import { LoaderService } from './services/loader.service';
import { AuthenticationService } from './services/auth.service';
import { AboutComponent } from './about/about.component';
import { AppConfigModule } from '../app-config.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AccessGuard } from './shared/guard/access.guard';
import { StorageService } from './services/storage.service';
import { LoginGuard } from './shared/guard/login.guard';
import { NoAccessPageComponent } from './no-access-page/no-access-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
//import { SerializationNumbersComponent } from './serialization-numbers/serialization-numbers.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppConfigModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  declarations: [
    AppComponent,
    AboutComponent,
    NoAccessPageComponent,
    PageNotFoundComponent,

    // BackGroundJobComponent
    //SerializationNumbersComponent
  ],
  providers: [AuthGuard, AccessGuard, LoginGuard, LoaderService, AuthenticationService, StorageService],
  exports: [AppRoutingModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
