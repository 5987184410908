<router-outlet>
    <div class="modal"  id="pleaseWaitModal" role="dialog" data-backdrop="static" data-keyboard="false" style="margin-top:15%; z-index: 100000 !important;">
        <div class="modal-dialog modal-xs">
            <div class="modal-content">
                <div class="modal-header" style="padding:5px;">
                    <h6  class="font-weight-semibold m-0">Please Wait...</h6>
                </div>
                <div class="modal-body" style="width: 100%; padding:5px;">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%;padding:5px;">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <span  class="loading" style="color:#fff;">
        <i class="fa fa-3x fa-spin fa-spinner"></i> Please Wait...</span> -->

    <!-- Confirm dialog box -->
    <div id="modal-confirm" class="modal fade in" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h2 class="modal-title w-100 font-weight-bold">Are you sure?</h2>
                </div>
                <div class="modal-body">
                    <h4 class="text-center">{{ConfirmDialogMsg}}</h4>
                </div>
                <div class="modal-footer text-center">
                    <div class="w-100">
                        <button type="button" id="modal-confirmOk" class="btn btn-primary" style="width: 100px;">Ok</button>
                        <button type="button" class="btn btn-secondary" id="modal-confirmCancel" style="width: 100px;" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Confirm dialog box end -->

</router-outlet>
