<div class="row" >
    <div class="col-md-6 col-xs-12 mt-15 mb-10 pl-20 | slice: startIndex : endIndex; ">
            <span class="label label-flat border-primary text-primary-600 text-bold">Showing from
                    <b>{{startIndex == 0 ? 1 : startIndex}}</b> to
                    <b>{{endIndex}}</b> of {{TableArray.length}} entries</span>
    </div>
    <div class="col-md-4 mt-15 mb-10 mr-10 pull-right">
        <div class="input-group">
                <span class="input-group-addon">
                        <i class="icon-filter4"></i>
                </span>
                <input type="text" class="form-control" [(ngModel)]="searchText"  placeholder="filter...">
        </div>
    </div>
</div>
<div class="table-responsive pre-scrollable" (scroll)="onScrollDown($event)">
<table [attr.id]="TableId" class="table table-striped table-bordered text-center" cellspacing="0">
    <thead>
        <tr class="alpha-slate" >
            <th class="text-center" *ngFor="let headerData of TableHeaderArray "><b>{{headerData}}</b></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of TableArray  | filter : searchText | slice: startIndex : endIndex;" [innerHtml] = "data">
        </tr>
    </tbody>
</table>
</div>