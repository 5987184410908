import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import { Observable }     from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from "../../services/auth.service";
import {tap} from 'rxjs/internal/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public router: Router,private auth: AuthenticationService) { }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Get the auth header from the service.
    //const authHeader = this.auth.token;
    // Clone the request to add the new header.
    //const authReq = req.clone({headers: req.headers.set('Authorization','Bearer ' + authHeader)});
    // Pass on the cloned request instead of the original request.
   // return next.handle(authReq);
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.router.navigateByUrl('/login');
          // redirect to the login route
          // or show a modal
        }
      }
    }));

  }
}