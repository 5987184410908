<div [class]="RadioBoxClass">
    <label>
        <div class="choice" (click)="Check()">
            <span>
                <input type="radio" [attr.name]="RadioBoxName" class="styled" >
            </span>
        </div>
        <b>{{RadioBoxText}}</b>
    </label>
</div>
