import { UserPermissionEnum } from "../../../user-management/enum/user-permision.enum";
import { NavbarItem } from "./navbar-item.model";

export const sidebarItems = [
  new NavbarItem({
    routerLink: ['/dashboard'],
    label: 'Dashboard',
    iconClass: 'icon-home4',
    children: [],
    permission: UserPermissionEnum.OPEN_FOR_ALL,
  }),
  new NavbarItem({
    routerLink: ['/products'],
    label: 'Products',
    iconClass: 'icon-stack2',
    children: [],
    permission: UserPermissionEnum.PRODUCT_READ,
  }),
  new NavbarItem({
    routerLink: ['/batchUniqueIdentity'],
    label: 'Batch Unique Identity',
    iconClass: 'icon-vcard',
    children: [],
    permission: UserPermissionEnum.BATCH_UNIQE_IDENTITY_READ,
  }),
  
  new NavbarItem({
    routerLink: ['/batches'],
    label: 'Batches',
    iconClass: 'icon-stack',
    children: [],
    permission: UserPermissionEnum.BATCH_READ,
  }),
  new NavbarItem({
    routerLink: ['/batchUpload'],
    label: 'Batch Upload',
    iconClass: 'icon-upload',
    children: [],
    permission: UserPermissionEnum.BATCH_UPLOAD,
  }),
  new NavbarItem({
    routerLink: ['/reconciliation'],
    label: 'Reconciliation',
    iconClass: 'icon-git-compare',
    children: [],
    permission: UserPermissionEnum.RECONCILIATION_READ,
  }),
  new NavbarItem({
    routerLink: ['/batch-sync-history'],
    label: 'Sync History',
    iconClass: 'icon-sync',
    children: [],
    permission: UserPermissionEnum.SYNC_HISTORY_READ,
  }),
  // new NavbarItem({
  //   routerLink: [],
  //   label: 'Batch Management',
  //   iconClass: 'icon-user-check',
  //   children: [
  //     new NavbarItem({
  //       routerLink: ['/batches'],
  //       label: 'Batches',
  //       iconClass: 'icon-stack',
  //       children: [],
  //       permission: UserPermissionEnum.BATCH_READ,
  //     }),
  //     new NavbarItem({
  //       routerLink: ['/batchUniqueIdentity'],
  //       label: 'Batch Unique Identity',
  //       iconClass: 'icon-user-check',
  //       children: [],
  //       permission: UserPermissionEnum.BATCH_READ,
  //     }),
  //   ],
  //   permission: UserPermissionEnum.NONE,
  // }),
  new NavbarItem({
    routerLink: [],
    label: 'User Management',
    iconClass: 'icon-user',
    children: [
        new NavbarItem({
          routerLink: ['/userrolebuiltin'],
          label: 'Built-in Roles',
          iconClass: 'icon-user-tie',
          children: [],
          permission: UserPermissionEnum.BUILT_IN_USER_ROLE_READ,
        }),
        new NavbarItem({
            routerLink: ['/user-role'],
            label: 'Roles',
            iconClass: 'icon-user-check',
            children: [],
            permission: UserPermissionEnum.USER_ROLE_READ,
          }),
          new NavbarItem({
            routerLink: ['/user'],
            label: 'Users',
            iconClass: 'icon-users',
            children: [],
            permission: UserPermissionEnum.USER_READ,
          }),
          new NavbarItem({
            routerLink: ['/settings/accountsettings'],
            label: 'Account Settings',
            iconClass: 'icon-vcard',
            children: [],
            permission: UserPermissionEnum.USER_ACCOUNT_SETTINGS_READ,
          }),
    ],
    permission: UserPermissionEnum.NONE,
  }),
  new NavbarItem({
    routerLink: [],
    label: 'Hierarchy',
    iconClass: 'icon-tree6',
    children: [
        new NavbarItem({
            routerLink: ['/hierarchy/company'],
            label: 'Company',
            iconClass: 'icon-library2',
            children: [],
            permission: UserPermissionEnum.COMPANY_READ,
          }),
          new NavbarItem({
            routerLink: ['/hierarchy/Plant'],
            label: 'Plants',
            iconClass: 'icon-tree5',
            children: [],
            permission: UserPermissionEnum.PLANT_READ,
          }),
          new NavbarItem({
            routerLink: ['/hierarchy/CMO'],
            label: 'CMOs',
            iconClass: 'fa fa-industry',
            children: [],
            permission: UserPermissionEnum.LLM_READ,
          }),
          new NavbarItem({
            routerLink: ['/hierarchy/plantlines'],
            label: 'Plantlines',
            iconClass: 'icon-tree7',
            children: [],
            permission: UserPermissionEnum.PLANT_LINE_READ,
          }),
          new NavbarItem({
            routerLink: ['/hierarchy/equipments'],
            label: 'Equipments',
            iconClass: 'icon-box',
            children: [],
            permission: UserPermissionEnum.EQUIPMENT_READ,
          }),
    ],
    permission: UserPermissionEnum.NONE,
  }),
  new NavbarItem({
    routerLink: [],
    label: 'Reports',
    iconClass: 'icon-graph',
    children: [
        new NavbarItem({
            routerLink: ['/report/production/productionreport'],
            label: 'Production Report',
            iconClass: 'icon-grid',
            children: [],
            permission: UserPermissionEnum.REPORT_PRODUCTION, 
          }),
        new NavbarItem({
            routerLink: ['/report/audit'],
            label: 'User Audit Report',
            iconClass: 'fa fa-file',
            children: [],
            permission: UserPermissionEnum.REPORT_AUDIT,
          }),
        // new NavbarItem({
        //     routerLink: ['/report/user'],
        //     label: 'User Report',
        //     iconClass: 'icon-user',
        //     children: [],
        //     permission: UserPermissionEnum.REPORT_USER,
        //   }),
        new NavbarItem({
            routerLink: ['/worker'],
            label: 'Background Jobs',
            iconClass: 'icon-chart',
            children: [],
            permission: UserPermissionEnum.REPORT_BACKGROUND_JOB,
          }),
    ],
    permission: UserPermissionEnum.NONE,
  }),
  new NavbarItem({
    routerLink: [],
    label: 'Master Settings',
    iconClass: 'icon-cog3',
    children: [
        new NavbarItem({
            routerLink: ['/settings/backupsettings'],
            label: 'Backup Settings',
            iconClass: 'icon-database-refresh',
            children: [],
            permission: UserPermissionEnum.BACKUP_SETTING_READ,
        }),
          new NavbarItem({
            routerLink: ['/settings/weblinksettings'],
            label: 'Digital Link Settings',
            iconClass: 'icon-qrcode',
            children: [],
            permission: UserPermissionEnum.DIGITAL_LINK_SETTINGS_READ,
          }),
          new NavbarItem({
            routerLink: ['/settings/batchsettings'],
            label: 'Batch Settings',
            iconClass: 'icon-stack',
            children: [],
            permission: UserPermissionEnum.BATCH_SETTINGS_READ,
          }),
          new NavbarItem({
            routerLink: ['/settings/emailnotifications'],
            label: 'Email Notifications',
            iconClass: 'icon-bell3',
            children: [],
            permission: UserPermissionEnum.EMAIL_NOTIFICATION_MASTER_READ,
          }),
          new NavbarItem({
            routerLink: ['/settings/codesettings'],
            label: 'Serial Number Status',
            iconClass: 'icon-grid5',
            children: [],
            permission: UserPermissionEnum.NONE,
          }),
          new NavbarItem({
            routerLink: ['/settings/packagesettings'],
            label: 'Packaging Level',
            iconClass: 'icon-stack2',
            children: [],
            permission: UserPermissionEnum.PACKING_SETTINGS_READ,
          }),
          new NavbarItem({
            routerLink: ['/settings/tpintegration'],
            label: 'Application Integrations',
            iconClass: 'icon-hyperlink',
            children: [],
            permission: UserPermissionEnum.TP_INTEGRATION_SETTINGS_READ,
          }),
          new NavbarItem({
            routerLink: ['/settings/customersettings'],
            label: 'MAH Settings',
            iconClass: 'icon-cog',
            children: [],
            permission: UserPermissionEnum.MAH_SETTINGS_READ,
          }),
          new NavbarItem({
            routerLink: ['/settings/systemsettings'],
            label: 'System Settings',
            iconClass: 'icon-cog',
            children: [],
            permission: UserPermissionEnum.NONE,
          }),
          new NavbarItem({
            routerLink: ['/settings/license'],
            label: 'License',
            iconClass: 'icon-libreoffice',
            children: [],
            permission: UserPermissionEnum.LICENCE_SETTINGS_READ,
          })
    ],
    permission: UserPermissionEnum.NONE,
  }),
  new NavbarItem({
    routerLink: ['/tutorial'],
    label: 'Tutorials',
    iconClass: 'icon-file-media',
    children: [],
    permission: UserPermissionEnum.OPEN_FOR_ALL,
  }),
];
