import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
import { LoginGuard } from './shared/guard/login.guard';
import { NoAccessPageComponent } from './no-access-page/no-access-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { AboutComponent } from './about/about.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [AuthGuard]
    },
    {   path: 'login', 
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate:[LoginGuard] 
    },
    //{ path: 'worker', component: BackGroundJobComponent },
    // { path: 'worker', loadChildren: () => import('./export/backgroundjob.module').then(bg => bg.BackGroundJobModule), pathMatch: "full" },
    { path: 'no-access', component: NoAccessPageComponent },
    { path: '**',component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,  {
        // Tell the router to use the HashLocationStrategy.
        useHash: false,
        enableTracing: false
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }