<div class="page-header page-header-default">
    <div class="page-header-content">
        <div class="page-title">
            <h4>
                <a [routerLink]="['/']" style="color:#000;">
                    <i class="icon-arrow-left52 position-left"></i>
                </a>
                <span class="text-semibold">About</span> - KevSecure Web Application
            </h4>
            <span class="text-muted text-semibold" style="padding-left:17.5em">Version - v1.1.0.0</span>
        </div>

    </div>
</div>
<div class="content">
    <div class="row">
        <div [class] = "featurePanelClass">
            <div class="panel panel-flat">
                <div class="panel-heading">
                    <h5 class="panel-title">
                        <b>Features of KevSecure Web Application </b>
                    </h5>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover text-nowrap">
                        <!-- <thead>
                                    <th class="col-md-2"> <b> Feature</b> </th>
                                    <th class="col-md-10"> <b> Description </b> </th>
                                </thead> -->
                        <tbody>
                            <tr>
                                <td class="col-md-2 pl-10" style="font-weight: bold">Company Management</td>
                                <td class="pl-10">
                                    <b>
                                        This module allows to create,update Company details. It also allows to
                                        integrate
                                        third party details like Tracelink.
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-md-2 pl-10" style="font-weight: bold">Plant Management</td>
                                <td class="pl-10">
                                    <b>
                                        This module allows to create,update and delete Plant details.
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-md-2 pl-10" style="font-weight: bold">PlantLine Management</td>
                                <td class="pl-10">
                                    <b>
                                        This module allows to create,update and delete PlantLine details.
                                    </b></td>
                            </tr>
                            <tr>
                                <td class="col-md-2 pl-10" style="font-weight: bold">Product Management</td>
                                <td class="pl-10">
                                    <b>
                                        This module allows to create,update and delete Product details.
                                    </b></td>
                            </tr>
                            <tr>
                                <td class="col-md-2 pl-10" style="font-weight: bold">Batch Management</td>
                                <td class="pl-10">
                                    <b>
                                        This module allows to create,update and delete Batch details.
                                    </b></td>
                            </tr>
                            <tr>
                                <td class="col-md-2 pl-10" style="font-weight: bold">User Management</td>
                                <td class="pl-10">
                                    <b>
                                        This module allows to create,update and delete Plant details.
                                    </b></td>
                            </tr>
                            <tr>
                                <td class="col-md-2 pl-10" style="font-weight: bold">Reports Management</td>
                                <td class="pl-10">
                                    <b>
                                        This module allows to generate Production & Audit Report .
                                    </b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div [class] = "featurePanelClass == 'col-lg-12' ? 'hide' : 'col-lg-3'  " >
            <div class="panel panel-white">
                <div class="panel-heading">
                    <h6 class="text-semibold panel-title">
                        <i class="icon-folder6 position-left"></i>
                        Related Documents
                        <a class="heading-elements-toggle"><i class="icon-more"></i></a></h6>

                    <div class="heading-elements">
                        <span class="heading-text text-muted"></span>
                    </div>
                </div>

                <div class="list-group no-border">
                    <a href="javascript:;" class="list-group-item" (click)="openDoc('ts')">
                        <i class="icon-file-pdf"></i> <b> Technical Specification</b>
                    </a>

                    <a href="javascript:;" class="list-group-item" (click)="openDoc('sop')">
                        <i class="icon-file-pdf"></i> <b> Sop </b>
                    </a>

                    <a href="javascript:;" class="list-group-item" (click)="openDoc('webapi')">
                        <i class="icon-file-pdf"></i> <b> WebApi </b>
                    </a>

                    <a href="javascript:;" class="list-group-item" (click)="openDoc('pr')">
                        <i class="icon-file-pdf"></i> <b> Production Report </b>
                    </a>

                    <a href="javascript:;" class="list-group-item" (click)="openDoc('ar')">
                        <i class="icon-file-pdf"></i> <b> Audit Report </b>
                    </a>

                    <a href="javascript:;" class="list-group-item" (click)="openDoc('flowchart')">
                        <i class="icon-file-pdf"></i> <b> Flowchart </b>
                    </a>

                    <a href="javascript:;" class="list-group-item" (click)="openDoc('sitemap')">
                        <i class="icon-file-pdf"></i> <b> SiteMap </b>
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>